/**
 * 导入
 * @requires [axios] - axios方法
 */
import CryptoJS from 'crypto-js'

import axios from 'axios'
import store from '@/store'
import { IMAGE_DB_CONFIG, IMAGE_DB_KEY, SAVE_COUNT_MAX_KEY, START_IMAGE_DB_KEY } from '../config/imgConfig'
import { decodeHttpResponseData } from './decodeResponceData'
import { decryptImage } from './imgCode'

//漫画api
import { comicsPay, comicsChapterIsLook, comicsChapterPics } from 'api/comics' //api列表

const {
  store_name,
  table_name,
  db_option
} = IMAGE_DB_CONFIG

/**
 * 解密图片
 * @param {string} [KEY] - 图片解密密钥【主要是使用密钥的长度】
 */

const KEY = Buffer.from('2019ysapp7527')

// 图片解密加自动保存
export function handleVerAutoImg(linkImg) {
  return new Promise(async (resolve, reject) => {
    if (!linkImg || !linkImg.length || !linkImg.includes('.')) resolve(false)
    const linkArray = linkImg.split('.')
    try {
      // 判断是否使用本地储存 还是使用网络请求

      if (!IMAGE_DB_CONFIG[START_IMAGE_DB_KEY]) {
        const originBase64 = await getImgBuffer()
        resolve(originBase64)
      } else {
        const ImageDB = IMAGE_DB_CONFIG[IMAGE_DB_KEY]
        if (!window.indexedDB || !ImageDB || ImageDB === undefined) {
          const originBase64 = await getImgBuffer()
          resolve(originBase64)
        }
        // 获取图片存储对象 并检测本地是否存储
        const transaction = ImageDB.transaction(store_name, db_option)
        const objectStore = transaction.objectStore(store_name)
        const index = objectStore.index(table_name)
        const response = index.get(linkImg)
        // 本地图片检测失败
        response.onerror = async function (event) {
          const originBase64 = await getImgBuffer()
          resolve(originBase64)
        }
        response.onsuccess = async function (event) {
          // 检测到本地存储有图片直接返回图片base64
          if (response.result !== undefined && response.result) {
            const { imgData } = response.result
            resolve(imgData)
          } else { // 本地没有缓存图片 下载后 压缩图片并直接存储 base64
            const base64Image = await getImgBuffer()
            const transaction = ImageDB.transaction(store_name, db_option)
            const objectStore = transaction.objectStore(store_name)
            const saveRes = objectStore.put({ src: linkImg, imgData: base64Image })
            saveRes.onsuccess = function () {
              // 判断存储是否已经达到上限 到达上限循环写入
              const saveIsMax = IMAGE_DB_CONFIG[SAVE_COUNT_MAX_KEY]
              if (!saveIsMax) return
              // 获取数据库第一个存储对象删除
              const keyRangeValue = IDBKeyRange.lowerBound(1)
              objectStore.openCursor(keyRangeValue).onsuccess = function (event) {
                const cursor = event.target.result
                if (cursor) cursor.delete()
              }
            }
            resolve(base64Image)
          }
        }
      }
    } catch (e) {
      reject(e)
    }
    function getBase64(result) {
      const bufArr = window.btoa(result)
      const baseImage = bufArr.substring(bufArr.indexOf(',') + 1)
      const realBase64 = 'data:image/'.concat(linkArray[linkArray.length - 1], ';base64,').concat(baseImage)
      return realBase64
    }

    function getImgBuffer() {
      return new Promise((resolve, reject) => {
        axios.get(linkImg, { responseType: 'arraybuffer', timeout: 100000 }).then((res) => {
          const data = res.data
          let binary = ''
          const bytes = new Uint8Array(data)
          const decryptBytes = decryptImage(bytes)

          const len = decryptBytes.byteLength
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(decryptBytes[i])
          }
          resolve(getBase64(binary))
        }).catch((e) => reject(e))
      })
    }
  })
}

/**
 * 单图解密图片主方法【被动调用方法】
 * @param {string} [linkImg] - 图片地址
 */
export function handleVerImg(linkImg) {
  return new Promise((resolve) => {
    axios
      .get(linkImg, { responseType: 'arraybuffer', timeout: 100000 })
      .then((res) => {
        const data = res.data
        const bytes = new Uint8Array(data)
        for (let i = 0; i < 100; i++) {
          bytes[i] ^= KEY[i % KEY.length]
        }
        // const type = 'data:image/png;base64'
        // const imgBase64 = type + transformArrayBufferToBase64(bytes);
        resolve(bytes)
        // resolve((window.URL) ? window.URL.createObjectURL(new Blob([bytes])) : window.webkitURL.createObjectURL(new Blob([bytes])))
      }).catch(err => {
        console.log(err);
      })
  })
}
// 请求参数加密
export function decodeParamToHttpRequest(params) {
  const { token, apiPath, XUserAgent, serect } = params
  const timestamp = parseInt(new Date(new Date().toUTCString()).getTime() / 1000)
  const nonce = getUuid()
  const signSting = token + '&' + apiPath + '&' + XUserAgent + '&' + timestamp.toString() + '&' + nonce
  // console.log('计算内容',signSting, 'token====+',token, 'apiPath====+',apiPath, 'XUserAgent====+',XUserAgent, 'serect====+',serect)

  const Sha1Encrypt = CryptoJS.HmacSHA1(signSting, serect)
  // console.log('计算结果', Sha1Encrypt)
  return `timestamp=${timestamp};sign=${Sha1Encrypt};nonce=${nonce}`
  function getUuid() {
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = '-'

    return s.join('')
  }
}
// 返回数据解密
export function decodeDataToHttpResponse(params) {
  if (params.code === 200 && params.hash) {
    const data = params.data
    params.data = JSON.parse(decodeHttpResponseData(data))
    return params
  } else {
    return params
  }
}

export function isUcOrBaiduOrShougouBrowser(params) {
  if (process.client) {
    const ua =
      navigator.userAgent.toLowerCase() ||
      window.navigator.userAgent.toLowerCase() ||
      ''
    const isUC = /UCBrowser|ucbrowser|UCWEB/.test(ua)
    const isBAIDU = /baidu|baiduboxapp|BAIDUBrowser|baidubrowser|baidumobilebrowser/.test(
      ua
    )
    const isSG = /sogoumobilebrowser|SE|MetaSr/.test(ua)
    const isWEIBO = /Weibo|weibo/.test(ua)
    return {
      isUC,
      isBAIDU,
      isSG,
      isWEIBO
    }
  }
}
export function getParams(url) {
  if (url === 'yinseinner://hq_wallet' || url === 'yinseinner://hq_home_hookup' || url === 'yinseinner://hq_sign_in_page' || url === 'yinseinner://hq_share_promotion') {
    const resultes = {
      urlParent: url
    }
    return resultes
  }
  if (url) {
    const str = url.split('?')
    const resultes = {
      urlParent: str[0]
    }
    if (str.length && str.length > 1) {
      const q = str[1].split('&')
      for (let i = 0; i < q.length; i++) {
        resultes[q[i].split('=')[0]] = unescape(q[i].split('=')[1])
      }
      return resultes
    }

    return resultes
  }
}
/**
 *
 * @param
 * typeCode:
 * @returns
// 视频  yinseinner://wb_video?id=
// 短视频  yinseinner://wb_player_list?id=
// VIP  yinseinner://wb_home_vip?tabIndex=1
// 钱包 yinseinner://wb_home_vip?tabIndex=0
// 直接播放页 yinseinner://wb_simple_player_page?url=/sp/av/3a4exxx.m3u8&w=1920&h=1080
// 分享邀请 yinseinner://wb_share_invite
// 全民代理 yinseinner://wb_member_agent
// 视频标签详情 yinseinner://wb_video_tag?name=&id=
// 短视频主题详情 yinseinner://wb_video_topic_more?name=&id=&showType=7
// 长视频主题详情 yinseinner://wb_video_topic_more?name=&id=
// 帖子详情 yinseinner://wb_post_detail?id=
// 帖子标签详情 yinseinner://wb_community_tag?name=&id=
// ai界面  yinseinner://wb_member_ai
 */
export function handleURlParams(url) {
  const params = getParams(url)
  const paramsTyep = {
    ...params,
    typeCode: ''
  }
  console.log(params, 'params');
  if (params) {
    if (params.hasOwnProperty('urlParent')) {
      switch (params.urlParent) {
        // 视频
        case 'yinseinner://wb_video':
          // 存在视频id 则跳往播放页面
          if (params.hasOwnProperty('id')) {
            paramsTyep.typeCode = 1
            return paramsTyep
          }
          break
        // 短视频
        case 'yinseinner://wb_player_list':
          // 存在短视频id 则跳往短视频播放页面
          if (params.hasOwnProperty('id')) {
            paramsTyep.typeCode = 2
            return paramsTyep
          }
          break
        case 'yinseinner://wb_short_video':
          // 存在短视频id 则跳往短视频播放页面
          // if (params.hasOwnProperty('id')) {
          paramsTyep.id = '推荐'
          paramsTyep.typeCode = 2
          return paramsTyep
          // }
          break
        // 钱包
        case 'yinseinner://wb_member_wallet':
          // 直接跳转钱包页
          paramsTyep.typeCode = 3
          return paramsTyep

        // 视频
        case 'yinseinner://wb_vip':
          // 存在视频id 则跳往播放页面
          if (params.hasOwnProperty('tabIndex')) {
            paramsTyep.typeCode = 5
            return paramsTyep
          }
          break
        // 楼凤
        case 'yinseinner://wb_loufeng_detail':

          if (params.hasOwnProperty('id')) {
            paramsTyep.typeCode = 7
            return paramsTyep
          }
          break
        // 女优
        case 'yinseinner://wb_model_detail':
          if (params.hasOwnProperty('id')) {
            paramsTyep.typeCode = 8
            return paramsTyep
          }
          break
        // 帖子详情
        case 'yinseinner://wb_post_details':
          if (params.hasOwnProperty('id')) {
            paramsTyep.typeCode = 9
            return paramsTyep
          }
          break
        // 分享
        case 'yinseinner://wb_share_invite':
          // 跳往分享
          paramsTyep.typeCode = 10
          return paramsTyep
        // 主题详情
        case 'yinseinner://wb_video_topic_more':
          paramsTyep.typeCode = 11
          return paramsTyep
        // 直接播放
        case 'yinseinner://wb_simple_player_page':
          if (params.hasOwnProperty('url')) {
            paramsTyep.typeCode = 13
            return paramsTyep
          }
          break
        // 标签
        case 'yinseinner://wb_video_tag':
          if (params.hasOwnProperty('id')) {
            paramsTyep.typeCode = 14
            return paramsTyep
          }
          break
        // 全民代理
        case "yinseinner://wb_member_agent":
          paramsTyep.typeCode = 15
          return paramsTyep
        // 全民代理
        case "yinseinner://wb_member_ai":
          paramsTyep.typeCode = 16
          return paramsTyep
        // 更多专题
        // case 'yinseinner://wb_video_topic_more':
        //   if (params.hasOwnProperty('tab')) {
        //     paramsTyep.typeCode = 16
        //     return paramsTyep
        //   }
        //   break
        // 抽奖活动
        case 'https://dhrt-wb.o3b4g1sa.cc/':
          paramsTyep.typeCode = 10086;
          return paramsTyep;
        // 用户任务/等级成长/签到
        case 'yinseinner://wb_member_sign':
          paramsTyep.typeCode = 17
          return paramsTyep
        default:
          return url
      }
    }
  } else {
    return paramsTyep
  }
}

// 判断type code 处理相关逻辑
export async function handleParamsRouteJump(router, ref, type, store) {

  console.log(type, '00');
  if (type.typeCode) {
    switch (type.typeCode) {
      case 1:
        // 跳到播放页
        router.push('/subpage/play/longVideo/' + Number(type.id))
        break
      // 跳到短视频播放页
      case 2:
        router.push({ path: `/subpage/play/shortVideo/${type.id == '推荐' && type.id}`, query: { detailId: type.id } })
        break
      case 3:
        // 跳到钱包
        router.push('/mine/newRecharge?type=0')
        break
      case 4:
        // 跳到活动详情
        router.push(`/activity/detail/${type.name}`)
        break
      case 5:
        // 跳到会员卡页面
        router.push(`/mine/newRecharge?type=${type.tabIndex}`)
        break
      // case 6:
      //   // 跳到演员详情
      //   router.push('/trade/AnHeiYouDetail/' + Number(type.id))
      //   break
      case 7:
        // 跳到楼凤
        router.push('/trade/AnHeiYouDetail/' + Number(type.id))
        break
      case 8:
        // 约炮详情
        router.push('/trade/DiWangTiaoJiaoDetail/' + Number(type.id))
        break
      case 9:
        router.push('/community/detail/' + Number(type.id))
        break
      case 10:
        // 分享页面
        router.push('/mine/agent')
        break
      // 更多主题
      case 11:
        router.push({ path: '/subpage/morePage/', query: { id: type.id, name: type.name } })
        break
      case 13:
        ref.fn_playVideo(type.url)
        break
      case 14:
        // 标签
        router.push({ path: '/subpage/labelPage/', query: { tagId: type.id, name: type.name } })
        break
      case 15:
        // 签到
        router.push('/mine/agent')
        break
      case 16:
        // 签到
        router.push('/ai/generate')
        break
      case 17:
        // 签到
        router.push({ path: '/mine/checkin/', query: { active: type.tabIndex } })
        break
      case 10086:
        type.urlParent = `${type.urlParent}?token=${store.state.user.token}&type=H5`;
        window.open(type.urlParent);
        break
      default:
        window.open(type.urlParent)
    }
  } else {
    if (type) {
      window.open(type)
    }
  }
}

// 中间件下载图片转blob
export function dataURItoBlob(base64Data) {
  var byteString
  if (base64Data.split(',')[0].indexOf('base64') >= 0) {
    byteString = window.atob(base64Data.split(',')[1])
  } else {
    // base64 解码
    byteString = unescape(base64Data.split(',')[1])
  }
  var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0] // mime类型 -- image/png

  // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
  // var ia = new Uint8Array(arrayBuffer);//创建视图
  var ia = new Uint8Array(byteString.length) // 创建视图
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  var blob = new Blob([ia], {
    type: mimeString
  })
  return blob
}

//  根据所需金币判断当前用户是否有足够金币购买  price 所需金币
export function canBuy(store, price) {
  if (store.state.user.info.balance / 100 < price / 100) {
    return false
  } else {
    return true
  }
}


// 单图、多图压缩
export function imgsCompress(fileList) {
  const promises = fileList.map((element) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(element.file)
      reader.onload = (e) => {
        const image = new Image()
        image.src = e.target.result
        image.onload = function () {
          //默认压缩后图片规格
          const quality = 0.5
          const w = this.width
          const h = this.height
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.width = w
          canvas.height = h
          context.drawImage(image, 0, 0, w, h)
          canvas.toBlob(
            (blob) => {
              const strName = element.file.name.split('.')[0] + '.jpeg'
              let obj = new File([blob], strName, { type: 'image/jpeg', lastModified: new Date() })
              resolve(obj)
            },
            'image/jpeg',
            quality
          )
        }
      }
    })
  })
  return Promise.all(promises)
}

// fn是我们需要包装的事件回调, delay是时间间隔的阈值
export function throttle(fn, delay) {
  // last为上一次触发回调的时间, timer是定时器
  let last = 0,
    timer = null;
  // 将throttle处理结果当作函数返回
  return function () {

    // 保留调用时的this上下文
    let context = this;
    // 保留调用时传入的参数
    let args = arguments;
    // 记录本次触发回调的时间
    let now = +new Date();
    // 判断上次触发的时间和本次触发的时间差是否小于时间间隔的阈值
    if (now - last < delay) {
      // 如果时间间隔小于我们设定的时间间隔阈值，则为本次触发操作设立一个新的定时器
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, delay);
    } else {
      // 如果时间间隔超出了我们设定的时间间隔阈值，那就不等了，无论如何要反馈给用户一次响应
      last = now;
      fn.apply(context, args);
    }
  };
}

export function isIphonex(index) { // 判断是不是X类型手机
  // X XS, XS Max, XR，11， 11pro，11pro max，12mini，12， 12 pro，12 pro max
  const xSeriesConfig = [
    {
      devicePixelRatio: 3,
      width: 375,
      height: 812,
    },
    {
      devicePixelRatio: 3,
      width: 414,
      height: 896,
    },
    {
      devicePixelRatio: 2,
      width: 414,
      height: 896,
    },
    {
      devicePixelRatio: 3,
      width: 315,
      height: 812,
    },
    {
      devicePixelRatio: 3,
      width: 390,
      height: 844,
    },
    {
      devicePixelRatio: 3,
      width: 428,
      height: 926,
    }
  ];
  // h5
  if (typeof window !== 'undefined' && window) {
    const isIOS = /iphone/gi.test(window.navigator.userAgent);
    if (!isIOS) return false;
    const { devicePixelRatio, screen } = window;
    const { width, height } = screen;
    if (index) {
      const item = xSeriesConfig[index]
      return item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height
    }
    return xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
  }
  return false;
}

//章节权限跳转  this 和章节id
export async function picViewRightTo(
  evtThis,
  picsId,
  price,
  type,
  isCollect,
  shoudBuy,
  novelId,
  novelType,
  bgImages,
  title,
) {
  let routeObj = { id: '', nowPage: '' }
  //漫画预览页
  if (evtThis.$route.path.includes('/home-comic/pic-view/')) {
    routeObj.id = +evtThis.$route.query.parentId
    routeObj.nowPage = 'pic-view'
  }
  //漫画介绍页
  if (evtThis.$route.path.includes('/home-comic/decial/')) {
    routeObj.id = +evtThis.$route.params.id
    routeObj.nowPage = 'decial'
  }

  //小说介绍页
  if (evtThis.$route.path.includes('/home-novel/decial/')) {
    routeObj.id = +evtThis.$route.params.id
  } else if (evtThis.$route.path.includes('/home-novel/word-view/')) {
    routeObj.id = +evtThis.$route.query.novelId
  } else if (evtThis.$route.path.includes('/home-novel/audioPlay/')) {
    routeObj.id = +evtThis.$route.query.id
  }

  let resOne = {}
  if (type === 1) {
    resOne = await evtThis.$novelIsLook({
      id: picsId,
    })
  } else {
    resOne = await comicsChapterIsLook({
      id: picsId,
    })
  }
  if (resOne.data.code == 200 && !resOne.data.playable) {
    return evtThis.$toast('请开通会员或购买章节')
  }
  // if (type === 1) {
  //   //小说的跳转判断
  //   if (resOne.data.code == 200 && resOne.data.playable) {
  //     if (evtThis.$route.path.includes('/home-novel/word-view/')) {
  //       evtThis.$router.replace({
  //         path: `/home-novel/word-view/` + picsId,
  //         query: {
  //           novelId: routeObj.id,
  //         },
  //       })
  //     } else if (evtThis.$route.path.includes('/home-novel/audioPlay')) {
  //       evtThis.$router.replace({
  //         path: '/home-novel/audioPlay',
  //         query: {
  //           id: routeObj.id,
  //           chapterId: picsId,
  //         },
  //       })
  //     } else {
  //       if (novelType) {
  //         evtThis.$router.push({
  //           path: '/home-novel/audioPlay',
  //           query: {
  //             id: routeObj.id,
  //             chapterId: picsId,
  //           },
  //         })
  //       } else {
  //         evtThis.$router.push({
  //           path: `/home-novel/word-view/` + picsId,
  //           query: {
  //             novelId: routeObj.id,
  //           },
  //         })
  //       }
  //     }
  //   } else if (resOne.data.code === 6139) {
  //     evtThis.$refs['popupMsg'].popupMsg = {
  //       show: true,
  //       title: '立即支付',
  //       content: price / 100,
  //       type: 'coin',
  //       ok: '立即解锁',
  //       cancel: '',
  //       position: 'bottom',
  //       ischeck: canBuy(evtThis.$store, price) ? true : false,
  //       cb: async (returnData) => {
  //         const res = await evtThis.$novelPay({
  //           id: routeObj.id,
  //         })
  //         if (res.data.code === 200) {
  //           evtThis.$refs['popupMsg'].popupMsg.show = false
  //           evtThis.$store.dispatch('user/get_userInfo')
  //           evtThis.$toast('购买成功')
  //         } else {
  //           evtThis.$router.push('/mine/wallet')
  //           return evtThis.$toast(res.data.msg)
  //         }
  //       },
  //     }
  //   } else {
  //     evtThis.$refs['popupMsg'].popupMsg = {
  //       show: true,
  //       title: '温馨提示',
  //       content: '您目前尚未成为vip，可立即前往成为VIP',
  //       type: 'text',
  //       ok: '立即前往',
  //       cancel: '',
  //       position: 'bottom',
  //       cb: async (returnData) => {
  //         evtThis.$router.push('/mine/newRecharge?type=1')
  //       },
  //     }
  //   }
  //   return
  // }

  //需要开通会员接口1
  if (resOne.data.code == 6101) {
    evtThis.$refs['popupMsg'].popupMsg = {
      show: true,
      title: '温馨提示',
      content: '您目前尚未成为vip，可立即前往成为VIP',
      type: 'text',
      ok: '立即前往',
      cancel: '',
      position: 'bottom',
      cb: async (returnData) => {
        evtThis.$router.push('/mine/newRecharge?type=1')
      },
    }
    return
  } else if (resOne.data.code == 6095) {
    evtThis.$refs['popupMsg'].popupMsg = {
      show: true,
      title: '立即支付',
      price: price,
      type: 'comic_pay_post',
      ok: '立即解锁',
      position: 'center',
      cb: async (returnData) => {
        const res = await comicsPay({
          id: routeObj.id,
        })
        if (res.data.code === 200) {
          evtThis.$refs['popupMsg'].popupMsg.show = false
          evtThis.$toast('购买成功')
          evtThis.$store.dispatch('get_userInfo')
        } else {
          evtThis.$router.push('/mine/newRecharge?type=0')
          return evtThis.$toast(res.data.msg)
        }
      },
    }
    return
  }
  //需要开通会员接口2
  evtThis.$toast('加载章节中...')
  const res = await comicsChapterPics({
    id: picsId,
  })
  if (res.data && res.code === 200) {
    res.data['isCollect'] = isCollect
    res.data['price'] = price
    res.data['shoudBuy'] = shoudBuy
    evtThis.$store.commit('setComicChapterObj', res.data)
    if (routeObj.nowPage == 'pic-view') {
      evtThis.$router.replace({
        path: `/home-comic/pic-view/` + picsId,
        query: {
          parentId: evtThis.$route.query.parentId,
          price: price ?? 0,
          bgImages: bgImages,
          title: title,
        },
      })
    } else {
      evtThis.$router.push({
        path: `/home-comic/pic-view/` + picsId,
        query: {
          parentId: evtThis.$route.params.id,
          price: price ?? 0,
          bgImages: bgImages,
          title: title,
        },
      })
    }
    return 200
  } else {
    //需要开通会员
    if (res.data.code == 6032 || res.data.code == 6101) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        title: '温馨提示',
        content: '您目前尚未成为vip，可立即前往成为VIP',
        type: 'text',
        ok: '立即前往',
        cancel: '',
        position: 'bottom',
        cb: async (returnData) => {
          evtThis.$router.push('/mine/newRecharge?type=1')
        },
      }
    } else if (res.data.code == 6100) {
      evtThis.$refs['popupMsg'].popupMsg = {
        show: true,
        title: '温馨提示',
        content: '付费即可观看本漫画',
        type: 'text',
        ok: '立即购买',
        cancel: '',
        position: 'bottom',
        cb: async (returnData) => {
          const res = await evtThis.$comicsPay({
            id: routeObj.id,
          })
          if (res.code === 200) {
            evtThis.$refs['popupMsg'].popupMsg.show = false
            evtThis.$store.dispatch('/get_userInfo')
            evtThis.$toast('购买成功')
          } else {
            evtThis.$router.push('/mine/wallet')
            return evtThis.$toast(res.tip)
          }
        },
      }
    } else {
      evtThis.$toast(res.data.msg || res.tip)
    }
    return res.data.code
  }
}

export function downVideo(url, name, type) {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url, true);
  xhr.responseType = 'arraybuffer'   // 返回类型blob
  xhr.onload = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let blob = this.response;
      // 转换一个blob链接
      let u = window.URL.createObjectURL(new Blob([blob], { type: type == 'video' ? 'video/mp4' : 'image/jpeg' })) // 视频的type是video/mp4，图片是image/jpeg
      let a = document.createElement('a');
      a.download = name; // 设置下载的文件名
      a.href = u;
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click();
      a.remove();
      xhr.abort()
    }
  };
  xhr.send()
}

export function fallback(vim) {
  const landRouteName = window.sessionStorage.getItem('landRouteName')
  if (vim.$route.name === landRouteName) {
    vim.$router.push("/");
    // 重置为首页
    window.sessionStorage.setItem('landRouteName', 'index')
  } else {
    vim.$router.go(-1);
  }
}
